<script setup lang="ts">
import { ref } from 'vue';
import { toast } from '@/helpers/toast';
import { isLoanAppComplete } from '@/helpers/typeGuards';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { useForm } from 'vee-validate';
import type { Applicant } from '@/types';
import { updateToken } from '@/api/backend/_http';
import { RelationshipType } from '@/helpers/const';
import Login from './modules/LoginModal/Login.vue';
import { AppValues } from '@/helpers/const';
const { authorisedUser, loanApp, state, send, saveAndGoNext, goBack } = useBrokerLoanAppProcess();

const { handleSubmit } = useForm({
  initialValues: { isRequired: loanApp.value.userType === 'Primary' },
  validateOnMount: false,
});

const isIncomeExpenseRequest =
  loanApp.value.userType === 'Secondary' &&
  state.value.context.from === 'crm' &&
  loanApp.value.actionType === 'updateExisting';

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const saveAndContinue = handleSubmit(() => {
  const primaryApplicant = loanApp.value.applicants.find((a) => a.type === 'primary');
  let applicants: Applicant[];
  if (primaryApplicant) {
    applicants = [primaryApplicant, ...loanApp.value.applicants.filter((a) => a.type !== 'primary')];
  } else {
    applicants = loanApp.value.applicants;
  }

  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
    },
  });

  if (loanApp.value.isResubmission) {
    submitApplicantData();
  } else {
    submitApplication();
  }
});

const showModal = ref(false);
const showLoginModal = ref(false);
const email = ref(loanApp.value.brokerEmail ?? '');
const modalType = ref<'canCancel' | 'cannotProceed'>('canCancel');
const appApi = useApplicationApi();
const isLoading = appApi.isLoading;

const submitApplication = async () => {
  if (!isLoanAppComplete(loanApp.value)) {
    console.error('loan incomplete!', loanApp.value);
    return toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }

  const result = await appApi.submitApplication({ loanApp: loanApp.value });
  if (!result.success) {
    if (result.error === 'invalid-jwt-token-error') {
      showLoginModal.value = true;
      return;
    }
    const errMsg = `A system error has occurred. Please try again otherwise contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support`;
    return toast({ type: 'danger', title: 'Error', description: errMsg });
  }

  toast({ type: 'success', title: 'Application submitted successfully' });
  // clear token after generating loan application?
  updateToken(null);
  saveAndGoNext();
};

const onConfirm = async () => {
  showModal.value = false;
  await submitApplication();
};

const submitApplicantData = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    const applicantIndex = loanApp.value.applicants.findIndex(
      (a) => loanApp.value.userType === 'Broker' || a.id === authorisedUser.id,
    );
    const result =
      isIncomeExpenseRequest && applicantsMarried
        ? await appApi.submitApplicants({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          })
        : await appApi.submitApplicant({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          });
    if (result.success) {
      toast({ type: 'success', title: 'Application submitted successfully' });
      // clear token after generating loan application?
      updateToken(null);
      saveAndGoNext();
    } else {
      toast({ type: 'danger', title: 'Error', description: result.error });
    }
  } else {
    console.error('loan incomplete!', loanApp.value);
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};

// This will backup applications created by the broker before submission.
// Broker backup is done here since brokers bypass the verification step.
if (loanApp.value.userType === 'Broker') {
  appApi.backupApplication(loanApp.value);
}
</script>

<template>
  <div>
    <div>
      <Login v-model:modelValue="showLoginModal" :broker-email="email" @close="showLoginModal = false" />
    </div>
    <div class="w-full">
      <div class="mb-4 pb-2">
        <h2 class="text-2xl font-medium">
          <BxHighlight>Confirmation</BxHighlight>
        </h2>
      </div>
      <div v-if="loanApp.actionType === 'createNew'">
        <div>
          <h2 class="mr-1 inline text-lg font-medium">
            Please provide any additional information regarding the loan application
          </h2>
          <span class="text-xs">(optional)</span>
        </div>
        <div>
          <p>
            Sharing additional information can help speed up the application process for you, and it gives our team a
            better picture of your unique situation. If there's anything you'd like to add, particularly about your
            asset position, feel free to share in the space below.
          </p>
        </div>
        <BxTextarea id="submission-reason-for-fund" v-model="loanApp.summaryNotes" class="mt-2 w-full" :rows="5" />
      </div>

      <div v-if="loanApp.userType === 'Broker'" class="my-8">
        By clicking <strong>Submit application</strong> I confirm that I am authorised by the applicant/s to make this
        loan application on their behalf.
      </div>
      <div v-else-if="loanApp.actionType === 'updateExisting'" class="my-8">
        Please click <strong>"Submit application"</strong> below to finalise your application.
      </div>
      <div class="mt-12">
        <BxButton id="submission-submit" variant="primary" :loading="isLoading" @click="saveAndContinue()"
          >Submit application</BxButton
        >
        <BxButton variant="link" class="ml-4" :disabled="isLoading" @click="goBack()">Back</BxButton>
      </div>
      <BxModal
        :open="showModal && modalType === 'canCancel'"
        :icon="ExclamationTriangleIcon"
        title="Warning"
        confirm-label="Yes"
        cancel-label="No"
        @close="showModal = false"
        @confirm="onConfirm()"
      >
        <h4>
          You have an existing active application, are you sure you want to proceed and cancel the previous application?
        </h4>
      </BxModal>
      <BxModal
        :open="showModal && modalType === 'cannotProceed'"
        :icon="ExclamationTriangleIcon"
        title="Warning"
        confirm-label="Yes"
        cancel-label="No"
        @close="showModal = false"
        @confirm="onConfirm()"
      >
        <h4>
          Our records indicate that there is currently an active application with these contact details. Please call us
          on {{ AppValues.CONTACT }} to discuss changing your application.
        </h4>
        <template #buttons>
          <BxButton variant="primary" @click="showModal = false">Close</BxButton>
        </template>
      </BxModal>
    </div>
  </div>
</template>
