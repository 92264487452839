import CustomerTypeStep from '@/views/CustomerTypeStep.vue';
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { loanApplicationRoutes } from './loanApplication';
import type { UtmMetricDto } from '@/api/backend/users/applications';
import { forceScrollToTop } from '@/helpers/forceScrollToTop';
import { useCookies } from '@vueuse/integrations/useCookies';
const cookies = useCookies();

declare module 'vue-router' {
  interface RouteMeta {
    layout?:
      | 'BrokerApplicationLayout'
      | 'CustomerApplicationLayout'
      | 'DeclarationLayout'
      | 'RequestIncomeExpenseLayout'
      | 'EmptyLayout';
    title?: string;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: CustomerTypeStep,
    props: ({ query }) => {
      const utmMetrics = <UtmMetricDto>{
        firstClickSource: (query.utm_source_f as string) ?? '',
        firstClickMedium: (query.utm_medium_f as string) ?? '',
        firstClickCampaign: (query.utm_campaign_f as string) ?? '',
        firstClickContent: (query.utm_content_f as string) ?? '',
        firstClickTerm: (query.utm_term_f as string) ?? '',
        secondClickSource: (query.utm_source as string) ?? '',
        secondClickMedium: (query.utm_medium as string) ?? '',
        secondClickCampaign: (query.utm_campaign as string) ?? '',
        secondClickContent: (query.utm_content as string) ?? '',
        secondClickTerm: (query.utm_term as string) ?? '',
        intSource: (query.int_source as string) ?? '',
      };
      const brokerToken = cookies.get('broker-token');
      return {
        loanType: query.loanType || 'buy_now',
        from: query.from,
        appType: query.appType,
        token: query.t,
        broker: query.broker ?? brokerToken,
        appId: query.appId,
        utmMetrics,
      };
    },
  },
  ...loanApplicationRoutes,
  // {
  //   path: '/customer',
  //   redirect: () => {
  //     restart(false);
  //     return { name: 'application' };
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      forceScrollToTop();
      resolve({ top: 0 });
    });
  },
});

router.beforeEach((to: RouteLocationNormalized) => {
  // set document title
  document.title = import.meta.env?.VITE_APP_CUSTOM_THEME_AGGREGATOR ?? 'Bridgit';
  if (to.path !== '/') {
    const title = getRouteMetaValue<string>(to, 'title');
    if (title) {
      document.title += ` - ${title}`;
    }
  }

  return true;
});

export function getRouteMetaValue<T>(
  route: RouteLocationNormalized,
  name: string,
  defaultValue: T | null = null,
): T | null {
  if (route.matched.length == 1) {
    return route.meta[name] !== undefined ? (route.meta[name] as T) : defaultValue;
  }
  const match = route.matched
    .slice()
    .reverse()
    .find((item) => item.meta[name] !== undefined);
  return match ? (match.meta[name] as T) : defaultValue;
}

export default router;
