import type { operations } from '@/types/schemas/api.main';
import { GET, setParams } from '../../_http';
type controller = operations['BrokerApplicationsRetrieveApplicationController_get'];

export type BrokerRetrieveApplicationQuery = controller['parameters']['query'];
export type BrokerRetrieveApplicationResponse = controller['responses']['200']['content']['application/json'];

export const retrieveBrokerApplication = GET(
  '/brokers/applications/application',
  setParams<BrokerRetrieveApplicationQuery>,
)<BrokerRetrieveApplicationResponse>;
