<script lang="ts">
export default {
  name: 'LoginComponent',
};
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { toast } from '@/helpers/toast';
import BxLoginModal from '@/components/common/BxLoginModal.vue';
import { useAuth } from '@/helpers/useAuth';

const props = defineProps<{
  modelValue: boolean;
  brokerEmail?: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const auth = useAuth();
const fullEmail = ref(props.brokerEmail ?? '');

// Function to truncate email at the 26th character
const truncatedEmail = computed(() => {
  if (!fullEmail?.value || fullEmail.value.trim() === '') {
    return 'Email not provided';
  }
  return fullEmail.value.length >= 26 ? fullEmail.value.slice(0, 20) + '.....' : fullEmail.value;
});

const otp = ref('');

const sendOtpCode = async () => {
  const result = await auth.sendBrokerOtp({ email: fullEmail.value });
  if (result.success) {
    toast({ type: 'success', title: 'Success', description: 'One time password sent successfully' });
  } else {
    toast({ type: 'danger', title: 'Unable to send code', description: 'Your OTP failed to send' });
  }
};

const signIn = async () => {
  if (!fullEmail.value.trim()) {
    toast({ type: 'danger', title: 'Email is missing', description: 'Email is missing' });
    return;
  }

  if (!otp.value.trim()) {
    toast({ type: 'danger', title: 'OTP is missing', description: 'OTP is missing' });
    return;
  }
  const result = await auth.verifyBrokerCredentials(fullEmail.value, otp.value);
  if (result.success) {
    toast({ type: 'success', title: 'Login successful', description: 'Login successful' });
    emit('close');
  } else {
    toast({ type: 'danger', title: 'Login unsuccessful', description: 'Login unsuccessful' });
  }
};

const handleNumber = (event: Event) => {
  (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/\D+/g, '');
};
</script>
<template>
  <BxLoginModal
    :open="modelValue"
    title=""
    confirm-label="Sign in"
    cancel-label="Cancel"
    class="login-modal"
    :show-partner-link="false"
    @confirm="signIn"
  >
    <p class="timeout-text">
      Your application session has timed out. Please sign in again below to continue the current application.
    </p>
    <p class="header-text">Log in to your account</p>
    <form class="login-form">
      <div class="mb-3">
        <div class="label mb-1">Enter your email address</div>
        <div class="relative">
          <BxInput
            v-model="truncatedEmail"
            type="email"
            class="email-field"
            placeholder="Email address"
            width="100%"
            disabled
          />
          <div class="send-code absolute cursor-pointer" @click="sendOtpCode">Send code</div>
        </div>
      </div>

      <div class="label mb-1">Enter the <span> 6 digit</span> verification code</div>
      <BxInput
        type="text"
        placeholder="Verification code"
        width="100%"
        maxlength="6"
        inputmode="numeric"
        @update:model-value="otp = $event ?? ''"
        @oninput:model-value="handleNumber($event as Event)"
      />
    </form>
  </BxLoginModal>
</template>
<style lang="scss">
.timeout-text {
  font-size: 14px;
  padding-bottom: 1rem;
}
.header-text {
  font-size: 28px;
  margin: 0;
  font-weight: 700;
}
.login-modal {
  max-width: 408px !important;
  padding: 2.5rem 2.5rem 2rem 2.5rem !important;
}
.login-form {
  margin-top: 24px;
}

.send-code {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
</style>
