<script setup lang="ts">
import sanitizeHtml from 'sanitize-html';
import { computed, ref, onMounted } from 'vue';
import { LoanPeriod, loanPeriodOptions } from '@/helpers/const';
import { formatCurrency, formatDate } from '@/helpers/format';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import type { LoanDetails } from '@/types';

defineProps<{
  data: LoanDetails;
}>();

const { loanApp } = useDeclarationProcess();

const descriptionHTML = ref<HTMLElement | null>(null);

const sanitizedDescription = computed(() => {
  if (!loanApp.value.additionalFundsRequired?.description) return '';

  return sanitizeHtml(loanApp.value.additionalFundsRequired?.description, {
    allowedTags: ['p', 'br'],
    allowedAttributes: {},
  });
});

const renderSanitizedDescription = () => {
  if (descriptionHTML.value) {
    descriptionHTML.value.innerHTML = sanitizedDescription.value;
  }
};

onMounted(() => {
  renderSanitizedDescription();
});
</script>

<template>
  <section class="summary-details">
    <h2>Loan details</h2>
    <h3>Additional funds</h3>
    <dl>
      <dt>Do you have any additional funds to contribute towards your purchase?</dt>
      <dd>{{ data.savingsAmount > 0 ? 'Yes' : 'No' }}</dd>
      <template v-if="data.savingsAmount > 0">
        <dt>Additional fund amount</dt>
        <dd>{{ formatCurrency(data.savingsAmount) }}</dd>
        <dt>Have any of these funds been gifted to you?</dt>
        <dd>{{ data.giftedAmount > 0 ? 'Yes' : 'No' }}</dd>
        <template v-if="data.giftedAmount > 0">
          <dt>Gifted funds</dt>
          <dd>{{ formatCurrency(data.giftedAmount) }}</dd>
        </template>
      </template>
    </dl>
    <h3>Loan amount - {{ formatCurrency(data.calcLoanAmount) }}</h3>
    <dl>
      <dt>Do you require additional funds?</dt>
      <dd>{{ loanApp.additionalFundsRequired?.fundsRequiredAmount ?? 0 > 0 ? 'Yes' : 'No' }}</dd>
      <template v-if="loanApp.additionalFundsRequired?.fundsRequiredAmount ?? 0 > 0">
        <dt>Additional funds amount</dt>
        <dd>{{ formatCurrency(loanApp.additionalFundsRequired?.fundsRequiredAmount ?? 0) }}</dd>
        <dt>Reason for additional fund</dt>
        <dd ref="descriptionHTML"></dd>
        <dt>Reason description</dt>
        <dd>{{ data.reasonDescription || '-' }}</dd>
      </template>
    </dl>
    <template v-if="data.loanPeriod">
      <h3>Loan date</h3>
      <dl>
        <dt>When do you need the funds?</dt>
        <dt>
          {{
            data.loanPeriod === LoanPeriod.EXACT_DATE ? formatDate(data.loanDate) : loanPeriodOptions[data.loanPeriod]
          }}
        </dt>
      </dl>
    </template>
  </section>
</template>
